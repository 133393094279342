import { useSearchBarContext } from '../SearchBarV2'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '@/app/common/graphql/hooks'
import tracking from '@/helpers/tracking'
import { UnstyledButton } from '@/style/components/Button'
import {
  SearchProductsDocument,
  SearchProductsQuery,
  SearchProductsQueryVariables,
} from '@/types/gql/graphql'
import ResultList, { Result } from './ResultList'
import { SearchInput } from './SearchBoxInput'
import IconSearch from './icon_search.svg'

const fiveMinutesInSeconds = 300

const Autocomplete: FunctionComponent = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const [searchTerm, setSearchTerm] = useState('')
  const { setIsSearchActive } = useSearchBarContext()
  const [searchQueryResults, setSearchQueryResults] =
    useState<SearchProductsQuery>()

  const router = useRouter()

  const [searchTime, setSearchTime] = useState(new Date())
  const [prevSearchTerm, setPrevSearchTerm] = useState(searchTerm)
  if (searchTerm !== prevSearchTerm) {
    setSearchTime(new Date())
    setPrevSearchTerm(searchTerm)
  }

  useQuery<SearchProductsQuery, SearchProductsQueryVariables>(
    SearchProductsDocument,
    {
      skip: !searchTerm,
      enableCaching: true,
      staleTime: fiveMinutesInSeconds,
      onCompleted: (data) => {
        setSearchQueryResults(data)
      },
      variables: {
        args: {
          search: searchTerm,
          isPublished: true,
          paginationArgs: {
            limit: 30,
          },
          createdBefore: searchTime,
        },
      },
    },
  )

  useEffect(() => {
    if (!searchTerm) return

    tracking.trackEvent('search', {
      'search-source': 'global-product',
      'search-term': searchTerm,
    })
  }, [searchTerm])

  const searchResults: Result[] | undefined = useMemo(() => {
    if (searchQueryResults === undefined) return
    return (
      searchQueryResults.searchProducts.nodes.map((node) => ({
        slug: node.slug,
        title: node.name,
      })) || []
    )
  }, [searchQueryResults])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!searchResults?.length) {
      if (selectedIndex !== undefined) {
        setSelectedIndex(undefined)
      }
      return
    }

    if (event.key === 'ArrowUp') {
      setSelectedIndex((current) => {
        if (current === undefined) return searchResults.length - 1

        if (current <= 0) return searchResults.length - 1

        return current - 1
      })
    } else if (event.key === 'ArrowDown') {
      setSelectedIndex((current) => {
        if (current === undefined) return 0

        if (current >= searchResults.length - 1) return 0

        return current + 1
      })
    } else if (event.key === 'Enter') {
      if (selectedIndex !== undefined) {
        setIsSearchActive(false)
        router.push(`/products/${searchResults[selectedIndex].slug}`)
      }
    }
  }

  return (
    <>
      <AutocompleteDiv onKeyDown={handleKeyDown}>
        <SearchInput
          onSearchTermChange={setSearchTerm}
          searchTerm={searchTerm}
        />

        <SearchButton>
          <SearchIcon />
        </SearchButton>

        <ResultList
          searchTerm={searchTerm}
          onBackdropClick={() => setSearchTerm('')}
          searchResults={searchResults}
          selectedIndex={selectedIndex}
        />
      </AutocompleteDiv>
    </>
  )
}

const AutocompleteDiv = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  min-width: 300px;
  width: 100%;
  z-index: 0;
`

const SearchButton = styled(UnstyledButton)`
  -webkit-appearance: none;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 0 ${({ theme }) => theme.radius.normal}px
    ${({ theme }) => theme.radius.normal}px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8rem;
`
const SearchIcon = styled(IconSearch)``

export default Autocomplete
