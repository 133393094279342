// NOTE: We need clean file names here! We will replace the content of the files on the cloud.
// There should be no date, version number in the file name!
import { EBusinessUnit, ERegionCode } from './types/gql/graphql'

export const STANDARD_SHIPPING_COSTS = 5
export const PREMIUM_SHIPPING_COSTS = 10

const fiftyYearsInSeconds = 1_576_800_000

export const CACHE_MAX_AGE = fiftyYearsInSeconds

const config = {
  apiHost: process.env.API_HOST ?? '',
  apiSSL: process.env.API_SSL === 'true',
  graphqlRoute: '/graphql',
  tusRoute: '/tus',
  healthCheckRoute: '/status',
  googleMapsKey: process.env.GOOGLE_API_KEY ?? '',
  checkoutExpirationMinutes: process.env.CHECKOUT_EXPIRATION_MINUTES
    ? parseInt(process.env.CHECKOUT_EXPIRATION_MINUTES)
    : 120,
  env: process.env.ENV ?? '',
  local: Boolean(process.env.LOCAL),
  enableNoIndexNoFollow: ['development', 'staging'].includes(
    process.env.ENV || '',
  ),
  storyblokAssetsProxyUrl:
    process.env.STORYBLOK_ASSETS_PROXY_URL ?? '/storyblok-assets/',
  storyblokPreviewToken: process.env.STORYBLOK_PREVIEW_TOKEN,
  // storyblok always uses the oldest preview token for validation ->
  // make it configurable separately
  storyblokPreviewVerificationToken:
    process.env.STORYBLOK_VERIFICATION_TOKEN ??
    process.env.STORYBLOK_PREVIEW_TOKEN,
  isStoryblokDevSpace: process.env.STORYBLOK_DEV_SPACE || false,
  hidePlaygroundStories:
    process.env.ENV !== 'staging' && !process.env.STORYBLOK_DEV_SPACE,
  isNewProductSearchEnabled: true,
}

// To generate uri base on host, support both http and ws
export const buildApiUri = (
  host: string | undefined,
  targetProtocol: 'http(s)' | 'ws(s)',
  path = '',
): string => {
  const { apiSSL, apiHost } = config
  const cleanProtocol = targetProtocol.slice(0, -3) // remove (s)
  const protocol = `${cleanProtocol}${apiSSL ? 's' : ''}://`

  if (apiHost) {
    if (typeof window !== 'undefined' && window.location.host !== host) {
      throw new Error('Invalid host')
    }
    return [protocol, apiHost, path].join('')
  }

  const uriWithoutProtocol = `api.${host}`
  return [protocol, uriWithoutProtocol, path].join('')
}

export default config

export const getFeesScheduleLink = (
  regionCode: ERegionCode,
  businessUnit: EBusinessUnit,
) => {
  if (regionCode === ERegionCode.De) {
    return '/gebuehrentarif'
  } else {
    if (businessUnit === EBusinessUnit.Car) return '/gebuehrentarif-kfz'
    else return '/gebuehrentarif'
  }
}
